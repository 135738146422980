<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
// import MagicTable from "@/components/MagicTable/MagicTable";
import AlertWidget from "@/components/general/AlertWidget";
import { required } from "vuelidate/lib/validators";
import TableRows from "@/views/pages/admin/shift/team-members/components/tableRows";


/**
 * Add-product component
 */
export default {
  page: {
    title: "Shifts Records",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {
    shifts: {
      type: [Object, Array],
      default: () => {
        return []
      }
    },
    status: {
         type: String, 
    },
    // acceptProcessing:{
    //   type: Boolean,
    // },
    // rejectProcessing:{
    //   type: Boolean,
    // },
    releaseProcessing:{
      type: Boolean,
    },
 
  },
  components: {
    AlertWidget,
    TableRows
  },
  data() {
    return {
      release_docs:null,
      
      processing:false,
      shiftTimeline: {},
      shift_id:null,
      timeline_id:null,
      releaseShiftModel:false,

      select_release_reason:null,
      release_reason:null,
      release_document:null,
      release_status:null,
      area_role_id:null,
    };
  },

  validations: {
    select_release_reason: {
      required,
    },
  },
  middleware: "authentication",

  computed:{
    recordExists: {
      get() {
        if(this.shifts.data)
        {
         return this.shifts.data.length > 0 ? true : false;
        }
        return false;
      },
    },
  },
  methods: {

      releaseBtnModel({timeline , status}){
        this.releaseShiftModel = true;
        this.shiftTimeline = timeline;
        this.release_status=status;
      },
      releaseDocs(release_docs)
      {
        this.release_docs = release_docs;
      },

      submitRelease() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } 

        this.$emit('releaseShift' , {
          status : this.release_status, 
          releaseDocument : this.release_document , 
          shiftTimeline : this.shiftTimeline  , 
          releaseReason : this.release_reason, 
          select_release_reason : this.select_release_reason
        });
      },

      closeModalBtn()
      {
        this.releaseShiftModel=false;
        this.release_document = null;
        this.release_reason = null;
        this.select_release_reason = null;
        this.$v.$reset();
      },
      acceptShift({status , timeline})
      {
        this.$emit('acceptRelease',{ status , timeline});
      },
      rejectShift({status , timeline})
      {
        this.$emit('rejectRelease', {status , timeline});
      },
  }
};
</script>

<template>
    <div>
               <div class="row mt-4">
      <div class="col-lg-12">
        <div class="dashboard__wrapper">
      
            <div class="table-responsive">
              <table class="table mb-0 table-bordered table-nowrap">
                <thead class="text-center">
                  <tr>
                    <th class="f-14 roboto">Employee Name</th>
                    <th class="f-14 roboto">User Email</th>
                    <th class="f-14 roboto">Contact</th>
                    <th class="f-14 roboto">Selected Role/Position</th>
                    <th class="f-14 roboto">Shift Name</th>
                    <th class="f-14 roboto">Shift From</th>
                    <th class="f-14 roboto">Shift To</th>
                    <!-- <th class="f-14 roboto">Selected Time From</th>
                    <th class="f-14 roboto">Selected Time To</th> -->
                    <th class="f-14 roboto">Status</th>
                    <th class="f-14 roboto">Location</th>
                    <th class="f-14 roboto" v-if="status=='Accepted' ">Release Shift</th>
                    <th class="f-14 roboto" v-if="status=='Released' || status=='ApplyForLeave'">Reason</th>
                    <th class="f-14 roboto" v-if="status=='ApplyForLeave'">Document</th>
                    <th class="f-14 roboto" v-if="!isTeamMember() && status!='Accepted' && status!='Released' && status!='Rejected' && status!='Completed' && $route.name == 'team_member_shift_listing'"> Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="recordExists">
                    <template v-for="(shift,index) in shifts.data" >
                      <TableRows :shift="shift" :status="status" :key="index + 'sss'" @accept="acceptShift($event);" @reject="rejectShift($event)" @releaseBtnModel="releaseBtnModel($event)" @releaseDocs="releaseDocs($event)"></TableRows>
                    </template>
                  </template>
                  <tr v-else>
                    <td colspan="13">
                      <AlertWidget  Widget class="mt-4" text="No Record Found"></AlertWidget>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-12 mt-3">
              <div class="dataTables_paginate paging_simple_numbers">
                <ul class="pagination justify-content-end py-0 my-0 gap-2">
                  <li
                    class="paginate_button page-item previous"
                    :class="shifts.prev_page_url == null ? 'disabled' : ''"
                  >
                    <button
                      type="button"
                      :class="shifts.prev_page_url == null ? 'disabled' : ''"
                      @click="$emit('paginatedData',shifts.prev_page_url)"
                      class="btn bg-light text-dark"
                    >
                      <i class="fas fa-arrow-left"></i>
                    </button>
                  </li>

                  <li
                    class="paginate_button page-item next"
                    :class="shifts.next_page_url == null ? 'disabled' : ''"
                  >
                    <button
                      type="button"
                      :class="shifts.next_page_url == null ? 'disabled' : ''"
                      @click="$emit('paginatedData',shifts.next_page_url)"
                      aria-controls="DataTables_Table_0"
                      data-dt-idx="8"
                      tabindex="0"
                      class="btn bg-light text-dark"
                    >
                      <i class="fas fa-arrow-right"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
        </div>
      </div>
    </div>

              <!-- end .border-->
                 <b-modal
      id="modal-attachment"
      size="lg"
      title="Leave Attachment"
      title-class="font-18"
      hide-footer
    >
      <div class="row">
        <div class="col-lg-12 border">
          <iframe
            v-if="release_docs != null"
            :style="{ height: '500px' }"
            width="100%"
            :src="assets_url + release_docs"
          >
          </iframe>
          <div v-else class="f-14 roboto align-middle">
            No Attachment Added from Employee
          </div>
        </div>
      </div>
    </b-modal>


          <b-modal
      v-model="releaseShiftModel"
      title="Release Shift"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
      no-close-on-backdrop no-close-on-esc hide-header-close
    >
      <form id="releaseShift">
        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center gap-2">
                <div class="icon-wrapper mt-3 pt-1">
                    <img src="@/assets/images/profile-manager/user-icon.png" width="21" alt="" />
                </div>
                <div class="form-field__wrapper w-100">
                    <label class="">Select Reason<span class="primaryColor"> * </span></label>
                      <select  id="work_area" v-model="select_release_reason" class="form-select"
                        :class="{'is-invalid':$v.select_release_reason.$error}"
                      >
                        <option :value="'Urgently released by management'" v-if="$route.name != 'shift_listing' && !isTeamMember()">Urgently released by management</option>
                        <option :value="'COVID quarantine required'" >COVID quarantine required</option>
                        <option :value="'Cold/Flu like symptoms'" >Cold/Flu like symptoms</option>
                        <option :vastatuslue="'Death in family'" >Death in family</option>
                        <option :value="'other'" >other</option>
                    </select>
                    <div v-if="!$v.select_release_reason.required" class="invalid-feedback">
                      Reason is required.
                    </div>
                </div>
            </div>
          </div>
          <div class="col-12 mt-2" v-if="select_release_reason=='other'">
            <div class="d-flex align-items-center gap-2">
              <div class="icon-wrapper mt-3 pt-1">
                  <img src="@/assets/images/profile-manager/icon-09.png" width="21" alt="" />
              </div>
              <div class="form-field__wrapper w-100">
                  <b-form-group id="input-group-2" label="Type Reason" label-for="input-2">
                      <textarea class="form-control"  :maxlength="225" rows="3" v-model="release_reason" placeholder="Write Reason" ></textarea>
                  </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-12 pt-4">
            <div class="d-flex align-items-center gap-2">
                <div class="icon-wrapper mt-4 pt-1">
                    <img src="@/assets/images/profile-manager/icon-10.png" width="21" alt="" />
                </div>
                <div class="form-field__wrapper w-100">
                  <div class="">
                  <label for="resume">Upload Document</label>
                  <input id="resume" type="file" @input="release_document = $event.target.files[0]" class="form-control-file form-control" />
                </div>
                </div>
            </div>
          </div>
        </div>

        <div class="text-end pt-5 mt-3">
          <button type="button" class="btn btn-outline-pink mx-3" @click="closeModalBtn">Close</button>
          <!-- <b-button @click="releaseShift" variant="success" class="ms-1"
            >Submit</b-button
          > -->
          <SubmitButton
            class="text-white"
            text="Submit"
            @clicked="submitRelease()"
            :processing="releaseProcessing"
          ></SubmitButton>
        </div>
      </form>
    </b-modal>


            </div>
</template>
