<template>
        <tr class="text-center" >
            <td class="f-14 roboto align-middle">{{ getSafe( () => (shift.employee.user.name)) }}</td>
            <td class="f-14 roboto align-middle">{{ getSafe( () =>shift.employee.user.email ) }}</td>
            <td class="f-14 roboto align-middle">{{getSafe( () => shift.employee.user.phone )}}</td>
            <td class="f-14 roboto align-middle">{{getSafe( () => shift.area_role.name )}}</td>
            <!-- <td class="f-14 roboto align-middle">
            {{DateWithMonthName(shift.shift.from)}}
            </td>
            <td class="f-14 roboto align-middle">
            {{DateWithMonthName(shift.shift.to)}}
            </td> -->
            <td class="f-14 roboto align-middle">{{getSafe( () => shift.shift.name )}}</td>
            <td class="f-14 roboto align-middle">
            {{getSafe( () => DateWithMonthName(shift.from) )}}
            </td>
            <td class="f-14 roboto align-middle">
            {{getSafe( () => DateWithMonthName(shift.to) )}}
            </td>
            <td class="f-14 roboto align-middle">
            {{status}}
            </td>
            <td class="f-14 roboto align-middle">
            {{getSafe( () => shift.shift.location.name )}}
            </td>

            <td class="f-14 roboto align-middle"  v-if="status=='Accepted'">
            <b-button variant="light" @click="$emit('releaseBtnModel',{timeline:shift,status:'Apply For Leave'})">Release</b-button>
            </td>
            <td v-if="status=='Released' || status=='ApplyForLeave'" class="f-14 roboto align-middle">
            {{getSafe( () => shift.action_detail )}}

            </td>
            <td v-if="status=='ApplyForLeave'" class="f-14 roboto align-middle">
            <!-- {{shift.release_docs}} -->
            <i
                @click="$emit('releaseDocs' , shift.release_docs)"
                v-b-modal.modal-attachment
                class="fas fa-eye"
            > 
            Click
            </i>
            </td>
    
            <td class="f-14 roboto align-middle" v-if="!isTeamMember() && status!='Accepted' && status!='Released' && status!='Rejected'  && status!='Completed' && $route.name == 'team_member_shift_listing'">
            
            
            <div
            
                class="action-buttons d-flex gap-2 justify-content-between"
            >
                <SubmitButton
                :processing="timeline.accept_processing"
                class="accept-button mt-3 text-white"
                text="Accept"
                @clicked="$emit('accept',{status: 'Accepted' ,timeline : timeline});"
                ></SubmitButton>

                <SubmitButton
                class="mt-3 text-white" 
                text="Reject"
                :color="'secondaryColorBg'"
                @clicked="$emit('reject',{status: 'Rejected' ,timeline : timeline});"
                :processing="timeline.reject_processing"
                ></SubmitButton>
            </div>
            </td>
        </tr>
</template>

<script>

export default {
    data()
    {
        return {
            timeline:{
                shift: {},
                accept_processing : false,
                reject_processing:false,
            }
        }
    },

    watch:{
        shift:
        {
            handler : function(newvalue , oldvalue)
            {
                this.timeline.shift = newvalue;
            },
            immediate: true,
            deep : true
        }
    },

    props: {
        shift:{
            type: [Object, Array],
            default: () => {
                return []
            }
        },
        status: {
         type: String, 
        },
    },

    methods:{

    }
}
</script>
