<template>
   <div :class="alertColorClass" class="alert text-center text-capitalize" role="alert">
      <slot name="icon" :icon="icon" :text="text">
         <i :class="icon" class="px-1"></i>{{ text }}
      </slot>
   </div>
</template>

<script>
export default {
   name: "AlertWidget",

   props: {
      icon: {
         type: String,
         default: "fas fa-exclamation-circle",
      },

      text: {
         type: String,
         default: "No record found",
      },

      alertColorClass: {
         type: String,
         default: "alert-warning"
      }
   },
}
</script>

<style scoped>

</style>
